@import './styles/variables';

.row {
  padding: 10px 0px;
}
.description {
  font-family: $introduction-font;
}


