@import '../../styles/variables.scss';

.display_board {
  font-family: $board-number-font;
  padding: 10px 10px;
  .title {
    font-size: 15px;
    position: relative;
    font-weight: 800;
    padding: 10px 0px;
    border-bottom: dashed 2px silver;
    img {
      position: absolute;
      bottom: 2px;
      left: 0px;
      width: 65px;
      height: 50px;
    }
    label {
      position: absolute;
      bottom: 10px;
      right: 5px;
    }
  }
  .numbers {
    font-size: 1.05rem;
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      td {
        color: rgb(110, 110, 110);
        padding: 8px;
        label {
          border-bottom: dashed 1px silver;
        }
      }
    }
  }

}
